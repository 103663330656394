import React        from 'react';
import {
  StyledInput,
  StyledInputWrapper,
  StyledLabel
}                   from "./styles";
import {fieldProps} from '@atlaskit/form';

export const CustomTextField = ({label, type, ...props}: fieldProps & React.InputHTMLAttributes<any>) => {
  return (
    <StyledInputWrapper>
      <StyledInput {...props} type={type || "text"} isInvalid={props.isInvalid} placeholder=" "/>
      <StyledLabel>{label}</StyledLabel>
    </StyledInputWrapper>
  );
};

export default CustomTextField;
