import { ApolloClient } from "apollo-client";
import { HttpLink } from "apollo-link-http";
import { ApolloLink, Observable } from "apollo-link";
import { InMemoryCache } from "apollo-cache-inmemory";
import { Operation } from "apollo-link/lib/types";
import { DocumentNode } from "graphql/language/ast";
import { OperationVariables } from "apollo-client/core/types";
import { RefetchQueryDescription } from "apollo-client/core/watchQueryOptions";
import { createUploadLink } from "apollo-upload-client";

export const endpoint = `/graphql`;
export const endpointWS = endpoint
  .replace(/^http:\/\//g, "ws://")
  .replace(/^https:\/\//g, "wss://");
export const token = `${process.env.REACT_APP_TOKEN_CRUD_API}`;

const request = async (operation: Operation) => {
  operation.setContext({
    headers: {
      "user-isn": localStorage.getItem("user-isn"),
      sid: localStorage.getItem("sid")
      // "Access-Control-Allow-Origin": '*'
    }
  });
};
const requestLink = new ApolloLink(
  (operation, forward) =>
    new Observable(observer => {
      let handle;
      Promise.resolve(operation)
        .then(op => request(op))
        .then(() => {
          handle = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer)
          });
        })
        .catch(observer.error.bind(observer));
      return () => {
        if (handle) {
          handle.unsubscribe();
        }
      };
    })
);
const uploadLink = createUploadLink({ uri: endpoint });
const linkFrom = ApolloLink.from([
  requestLink,
  uploadLink,
  new HttpLink({
    uri: endpoint
    // credentials:'include'
  })
]);
const link = /*endpointWS ? ApolloLink.split(
  ({query:{definitions}}) =>
    definitions.some(({kind,operation}: any) => kind==='OperationDefinition'&&operation==='subscription'),
  new WebSocketLink(new SubscriptionClient(endpointWS,{reconnect:true})),linkFrom)
  : */ linkFrom;
export const client = new ApolloClient({
  link,
  cache: new InMemoryCache()
});
export default async function query(
  gql: DocumentNode,
  variables: OperationVariables,
  newClient: any = undefined
) {
  return (await (newClient || client).query({ query: gql, variables })) as any;
}

export async function mutate(
  mutation: DocumentNode,
  variables: OperationVariables,
  refetchQueries: RefetchQueryDescription = []
) {
  return (await client.mutate({ mutation, variables, refetchQueries })) as any;
}

export async function subscribe(
  gql: DocumentNode,
  variables: OperationVariables
) {
  return (await client.subscribe({ query: gql, variables })) as any;
}
