import * as React from "react";
import { ErrorMessage } from "@atlaskit/form";
import styled from "styled-components";

const ReloadButton = styled.div`
  padding: 10px 20px;
  border: 2px solid #778296;
  max-width: fit-content;
  margin-top: 20px;
  border-radius: 5px;
  cursor: pointer;
`;

const LoadingError = (): JSX.Element => (
  <>
    <h3>
      <ErrorMessage>При загрузке произошла ошибка</ErrorMessage>
    </h3>
    <ReloadButton onClick={() => window.location.reload()}>
      Попробовать еще раз
    </ReloadButton>
  </>
);

export default LoadingError;
