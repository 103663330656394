import {styled} from '../../styled/styled-components';

export const StyledContactsModalBody = styled.div` 
  padding: 0 3rem 3rem 3rem;
  display: flex;
  flex-direction: column;
  background: white; 
  div[class$="control"] {
    margin: 2rem 0 4rem 0;
  } 
  
  p {
    color: black;
    opacity: 0.5;
    margin: 0;
    padding: 2rem 0 1rem 0;
  }
  h1 , h3 {
    margin: 0;
    padding: 0 0 2rem 0;
    color: black;
    border-bottom: 1px solid #E6E6E6;
  }
  
  @media screen and (max-width: 767px) {
    padding: 0 1.5rem 1.5rem 1.5rem;
  }
`;
export const StyledContactsModal = styled.div` 
  @media screen and (max-width: 767px) {
    overflow-x: scroll;
    background: white;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
`;
