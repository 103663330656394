import React                     from 'react';
import {StyledFeaturesComponent} from '../styles'
import {FeaturesIcon}            from "../../../icons/ui/Features";
import { useQuery } from '@apollo/react-hooks';
import {ErrorMessage}          from '@atlaskit/form';
import { GET_API_DATA } from '../../../utils/queries';
import LoadingError from "../../../components/page/LoadingError";

const Features = () => {

  const {data, loading} = useQuery(GET_API_DATA, {
    variables: {
      name: "auth"
    }
  });

  if (loading) return null;
  const authDataFromApi = data ? JSON.parse(data.singleton) : undefined;
  if (authDataFromApi.error) return <LoadingError/>;

  return (
    <StyledFeaturesComponent>
      <div>
        {((authDataFromApi||{}).features||{}).title && <h1>{((authDataFromApi||{}).features||{}).title}</h1>}
        {(((authDataFromApi||{}).features||{}).list||[]).length && (((authDataFromApi||{}).features||{}).list||[]).map(({value}, index) => (
          <p key={"feature" + index}><FeaturesIcon/>{value}</p>
        )) }
      </div>
    </StyledFeaturesComponent>
  );
};
export default Features;
