import React              from 'react';
import Features           from './components/Features';
import LoginForm          from './components/LoginForm';
import {Grid, GridColumn} from '@atlaskit/page';
import {StyledAuthGrid}   from './styles';
import LoginFormLayout    from './components/LoginFormLayout';
import useResize          from 'use-resize';
import {useHistory}       from 'react-router';
import StoreNavigation    from "../../components/navigation/store";

const Authorization = () => {
  const pageSize = useResize();
  const history = useHistory();
  const [{navigation: {navigationState}}, {navigation: actionsNavigation}] = StoreNavigation();
  const toggleNavigation = () => actionsNavigation.setState({
    navigationState: {
      ...navigationState,
      isOpen: !navigationState.isOpen
    }
  });

  if (navigationState.isOpen) {
    toggleNavigation();
  }

  if (localStorage.getItem('sid')) {
    history.push('/');
    return null;
  }
  return (
    <StyledAuthGrid>
      <Grid layout="fluid">
        <GridColumn medium={pageSize.width > 1023 ? 6 : 12}>
          <LoginFormLayout>
            <LoginForm/>
          </LoginFormLayout>
        </GridColumn>
        <GridColumn medium={pageSize.width > 1023 ? 6 : 12}>
          <Features/>
        </GridColumn>
      </Grid>
    </StyledAuthGrid>
  );
};
export default Authorization;
