import {styled, withProps} from "../styled/styled-components";
import {theme}             from "../styled/theme";

export const StyledNavigation = withProps<{
  isAuth?: boolean;
  leftMenuIsOpen?: boolean;
}>()(styled.div)`
  > div div {
  ${({isAuth}) =>
  isAuth
    ? `
    margin-top: 0;
  `
    : ""}
  }

  @media screen and (max-width: 767px) {
    ${({leftMenuIsOpen}) =>
  leftMenuIsOpen
    ? `
      overflow-x: hidden;
  `
    : ""}
  }

  > div > div:first-child {
    ${({leftMenuIsOpen}) =>
  leftMenuIsOpen
    ? `
      left: 26rem;
      width: calc(100vw - 26rem);
  `
    : `
      left: 0;
      width: 100vw;`}
    will-change: width, left;
    transition: all 300ms cubic-bezier(0.2, 0, 0, 1) 0s;
  }

  > div > div:first-child > div {
    background: white;
    padding: 0 4rem;

    @media screen and (max-width: 767px) {
    padding: 0 1.5rem;
    }
  }


  > div > div:nth-child(2) {
    top: 0;
  }

  > div > div:nth-child(2) > div > div {
    height: 100vh!important;
  }

  > div > div:nth-child(2) > div > div > div {
    background-color: #fff;
  }

  > div > div:nth-child(2) > div > div > div > div > div > div > div::before,
  > div > div:nth-child(2) > div > div > div > div > div > div > div::after {
    background-color: #fff;
  }

  > div > div:nth-child(2) > div > div > div > div > div > div > div > div {
    padding: 0;
  }
`;

export const StyledMainHeader = styled.div`
  > div {
    padding: 0;
    height: 6rem;
  }

  > div > * {
    margin-right: 0;
  }

  > div::after {
    background: none;
  }
`;

export const StyledLeftMenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const StyledMobileDarkBackground = withProps<{
  isOpen?: boolean;
}>()(styled.div)`
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background: black;
  opacity: 0.5;
  visibility: hidden;
  left: 0;
    ${({ isOpen }) =>
      isOpen
        ? `
    @media screen and (max-width: 767px) {
      visibility: visible;
      left: 26rem;
    }
    `
        : `
    `}
    will-change: left;
    transition: left 300ms cubic-bezier(0.2, 0, 0, 1) 0s;
`;

export const StyledLeftMenuItems = styled.div`
  flex: 1 0 auto;
`;

export const StyledContactsLeftMenu = styled.div`
  flex: 0 0 auto;
  padding: 4rem 0 4rem 4rem;
  div {
    color: black !important;
    white-space: nowrap;
    margin-bottom: 0.8rem;
    display: flex;
    align-items: center;
    font-weight: bold;
  }

  div a {
    white-space: nowrap;
  }

  div:hover {
    cursor: pointer;
    color: ${theme().colours.lightBlue}!important;
  }

  div:hover a {
    cursor: pointer;
    color: ${theme().colours.lightBlue}!important;
  }

  div span {
    margin-right: 1rem;
  }
  p {
    color: black;
    padding-right: 2rem;
    margin-top: 3rem;
    opacity: 0.5;
  }

  a {
    text-decoration: none;
    color: black !important;
    outline: none;
  }
  a:hover,
  a:focus {
    text-decoration: none;
    color: black !important;
    outline: none;
  }
`;

export const StyledLeftMenuItem = withProps<{
  selected: boolean;
}>()(styled.div)`
  > a, button {
    border: none;
    cursor: pointer;
    white-space: nowrap;
    display: flex;
    align-items: center;
    padding: 0 0 0 4rem;
    border-radius: 0;
    height: 5rem;
    background-color: #fff;
    font-size: 1.4rem;
    opacity: 0.5;
    color: black!important;
    border-left: 0;
    ${({ selected }) =>
      selected &&
      `
      opacity: 1;
      padding: 0 0 0 3.5rem;
      font-weight: bold;
      border-left: 0.5rem solid #003D81;
      background-color: #EDF1F6;
    `}
  }

  > button {
    width: 100%;
  }

  > a:hover,
  > a:active,
  > a:focus,
  > button:hover,
  > button:active,
  > button:focus
   {
    text-decoration: none;
    color: black!important;
    opacity: 1;
    outline: none;
  }
`;

export const StyledLeftMenuLogo = styled.div`
  > a:hover,
  > a:active,
  > a:focus {
    outline: none;
  }
`;

export const StyledLogo = styled.div`
  margin: 2rem 0 3rem 4rem;
`;

export const StyledLogoMobile = styled.div`
  margin-left: 1.5rem;
`;

export const ContentStyle = withProps<{
  isAuth?: boolean;
  isWhiteBackground?: boolean;
}>()(styled.div)`
  ${({ isAuth }) =>
    isAuth
      ? `
    padding: 0;
  `
      : `
    padding: 2rem 4rem 8rem 4rem;

    @media screen and (max-width: 1023px) {
    padding: 2rem 4rem 8rem 4rem;
    min-width: 100vw;
  }
    @media screen and (max-width: 767px) {
    padding: 4rem 1.5rem 8rem 1.5rem;
    min-width: 100vw;
  }
  `}

  ${({ isWhiteBackground }) =>
    isWhiteBackground
      ? `
  `
      : `

    background: #F7F9FB;
  `}
  min-height: 100%;
  box-sizing: border-box;
`;

export const StyledTable = withProps<{
  isPagination?: boolean;
}>()(styled.div)`
  overflow: hidden;
  overflow-y: hidden;
  overflow-x: auto;
  background: white;

  > div {
    position: unset;
    margin-bottom: 0;
  }

  table thead,
  tbody {
    border-bottom: 1px solid #e6e6e6;
  }

  table {
    padding: 0 3rem;
    background: white;
    table-layout: auto;
  }

  table label {
    margin: 0;
    width: 24px;
  }
  table label > span > input {
    width: 100%;
    height: 100%;
  }

  table thead tr {
    height: 4rem;
  }

  table thead tr th {
    vertical-align: middle;
    padding: 0 3rem 0 1rem;
    text-transform: uppercase;
    color: #999999;
    outline: none;
    white-space: nowrap;
    font-weight: bold;
    font-size: 1.1rem;
  }
  table thead th:first-child {
    min-width: 3rem;
    padding-left: 3rem;
  }
  table thead th:last-child {
    min-width: 10rem;
    padding-right: 3rem;
  }

  table thead th:focus {
    outline: none;
  }

  table tbody tr {
    height: 6rem;
    cursor: pointer;
    border-bottom: 1px solid #e6e6e6;
  }

  table tbody td {
    white-space: nowrap;
    padding-left: 1rem;
    min-width: 5rem;
    font-size: 1.4rem;
    color: black;
  }

  table tbody td:first-child {
    color: #808080;
    padding-left: 3rem;
  }
  table tbody td:last-child {
    padding-right: 3rem;
  }

  table tbody tr:hover {
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  }

  > div:last-child > div > button {
    border-radius: 0;
  }
  > div:last-child {
    background: white;
  }

  ${({ isPagination }) =>
    isPagination
      ? ""
      : `
      > div:last-child > div >  button{
        display: none;
      }
      > div:last-child > div > span {
        display: none;
      }
    `}
`;

export const StyledIconButton = styled.div`
  button > span > span {
    display: flex;
  }
  button:hover > span > span {
    color: ${theme().colours.lightBlue};
  }
`;

export const StyledMoreIconButton = styled.div`
  button > span > span {
    display: flex;
  }
  button {
    height: 3.6rem;
    margin-left: 3rem;
  }
`;

export const StyledHeaderButtons = styled.div`
  display: flex;
  button {
    padding: 0;
    margin-left: 1rem;
    width: 3.6rem;
    height: 3.6rem;
    display: inline-flex;
    justify-content: center;
    border-radius: 0;
  }
  button:hover {
    background: #edf1f6;
  }
`;

export const StyledPlaceForError = styled.div`
  position: absolute;
  top: 0:
  width: 100%;
  > div {
    color: #D23630;
    display: flex;
    align-items: center;
    margin: 0;
    font-size: 1.2rem;
  }
`;

export const StyledPlaceForInfoMessage = styled.div`
  position: absolute;
  top: 0:
  width: 100%;
  > div {
    color: rgb(98, 182, 83);
    display: flex;
    align-items: center;
    margin: 0;
    font-size: 1.2rem;
  }
`;

export const StyledToTopButton = styled.div`
  cursor: pointer;
  position: fixed;
  width: 4.6rem;
  height: 4.6rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  color: white;
  z-index: 150;
  background: ${theme().colours.lightBlue};
  :hover {
    background: ${theme().colours.blue};
  }

  bottom: 14rem;
  right: 4rem;
  @media screen and (max-width: 767px) {
    bottom: 7.7rem;
    right: 1.7rem;
  }
`;

export const StaledLabelWithIcon = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
  text-decoration: underline;
  color: ${theme().colours.blue};

  svg {
    overflow: visible;
  }
  > a {
    display: flex;
    align-items: center;
  }
  :hover {
    color: ${theme().colours.hoverBlue};
  }
  span {
    margin-left: 1rem;
  }
`;
export const StyledBackButton = styled.button`
  color: #949597;
  border: 0;
  padding: 0;
  font-size: 1.4rem;
  display: flex;
  background: inherit;
  align-items: center;
  svg {
    margin-right: 0.8rem;
    transform: rotate(90deg);
  }
  :focus,
  :hover {
    outline: none;
    color: ${theme().colours.lightBlue};
  }
`;
