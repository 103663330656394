import {styled, withProps} from "../../../styled/styled-components";
import {theme}  from "../../../styled/theme";

export const StyledInputWrapper = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  overflow: hidden;
  
  .datePickerIcon {
    z-index: 251;
    position: absolute;
    right: 1.5rem;
    height: 100%;
    display: flex;
    align-items: center;  
    }
    
    
  .datePickerIcon:hover {
    cursor: pointer;
    color:  ${theme().colours.lightBlue};
  }
`;

export const StyledInput = withProps<{
  isInvalid?: boolean
  }>()(styled.input)`
  
  ${({isInvalid}) =>
  isInvalid
  ?
  `
    border-color: #D23630!important;
  `
  :
  ``
  }
  height: 6rem;
  padding: 0 1.5rem;
  width: calc(100% - 3rem);
  transition: all 0.2s;
  touch-action: manipulation;
  font-size: 1.4rem;
  border: 0;
  border: 1px solid #ccc;
  font-family: inherit;
  -webkit-appearance: none;
  border-radius: 0;
  cursor: text;

:focus {
  outline: 0;
  border: 1px solid ${theme().colours.lightBlue};
}

:hover {
  border: 1px solid ${theme().colours.lightBlue};
}

:placeholder-shown + label {
  cursor: text;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transform-origin: left bottom;
  transform: translate(1.5rem, 2rem) scale(1);
}

::-webkit-input-placeholder {
  opacity: 0;
}

:not(:placeholder-shown) + label,
:focus + label {
  transform-origin: left bottom;
  transform: translate(1.5rem, 0) scale(0.8);
  cursor: pointer;
}
`;
export const StyledLabel = styled.label` 
  color: #808080;
  position: absolute;
  pointer-events: none;
  transition: all 0.2s;
  touch-action: manipulation;
  white-space: nowrap;
`;
