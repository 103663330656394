interface ThemeInterface {
  shadows: Shadows;
}

export interface ThemeInterfaceLight extends ThemeInterface {
  colors: ColorsLight;
  colours: ColoursLight;
  buttons: Buttons;
}

interface ColorsLight {
  navigationBackground: "#fff";
  background: "#fff";
  backgroundContainer: "#f4f5f7";
  cardBackground: "#fff";
  textColor: "#000000";
  textContainer: "#42526e";
}

interface ColoursLight {
  white: "#ffffff";
  // transparent
  blue: "#003D81";
  darkBlue: "#012752";
  // filled
  lightBlue: "#41B6E6";
  hoverBlue: "#299DCD";
  clickBlue: "#037BAC";

  lightishGray: "#e5e9eb";
  gray: "#cccccc";
  darkGray: "#7e9aa8";
  green: "#15bd76";
  red: "#ff4f56";
  orange: "#ff8934";
  dark: "#0c344b";
  lightBlack: "#383838";
  purple: "#6375df";
}

interface Shadows {
  thin: "0px 1px 0px rgba(12, 52, 75, 0.05)";
  small: "0px 1px 3px rgba(12, 52, 75, 0.05)";
  normal: "0px 3px 3px rgba(12, 52, 75, 0.05)";
}

export const colorsLight: ColorsLight = {
  navigationBackground: "#fff",
  background: "#fff",
  backgroundContainer: "#f4f5f7",
  cardBackground: "#fff",
  textColor: "#000000",
  textContainer: "#42526e"
};
export const coloursLight: ColoursLight = {
  white: "#ffffff",
  // transparent
  blue: "#003D81",
  darkBlue: "#012752",
  // filled
  lightBlue: "#41B6E6",
  hoverBlue: "#299DCD",
  clickBlue: "#037BAC",

  lightishGray: "#e5e9eb",
  gray: "#cccccc",
  darkGray: "#7e9aa8",
  green: "#15bd76",
  red: "#ff4f56",
  orange: "#ff8934",
  dark: "#0c344b",
  lightBlack: "#383838",
  purple: "#6375df"
};

const shadows: Shadows = {
  thin: "0px 1px 0px rgba(12, 52, 75, 0.05)",
  small: "0px 1px 3px rgba(12, 52, 75, 0.05)",
  normal: "0px 3px 3px rgba(12, 52, 75, 0.05)"
};

interface Buttons {
  default: {
    background: "#fff";
    text: "#000";
  };
  focused: {
    background: "#fff";
    text: "#000";
  };
  hover: {
    background: "red";
    text: "#000";
  };
}

const buttons: Buttons = {
  default: {
    background: "#fff",
    text: "#000"
  },
  focused: {
    background: "#fff",
    text: "#000"
  },
  hover: {
    background: "red",
    text: "#000"
  }
};
export const theme: () => ThemeInterfaceLight = () =>
  Object.assign(
    {
      shadows
    },
    {
      colors: colorsLight,
      colours: coloursLight,
      buttons
    }
  );
