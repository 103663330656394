import React from "react";
import { ApolloError, OperationVariables } from "apollo-client";
import { DocumentNode, GraphQLError } from "graphql";
import { useApolloClient } from "@apollo/react-hooks";
import { REFRESH_SID } from "./mutations";
import { useHistory } from "react-router";

export function useLazyQuery<TData = any, TVariables = OperationVariables>(
  query: DocumentNode
) {
  const client = useApolloClient();
  return React.useCallback(
    (variables: TVariables) =>
      client.query<TData, TVariables>({
        query: query,
        variables: variables
      }), // eslint-disable-next-line
    [client]
  );
}

export function HandleSessionError<
  TData = any,
  TVariables = OperationVariables
>(callback?: () => void) {
  const client = useApolloClient();
  const history = useHistory();
  return React.useCallback(
    (
      errorsOrMutateCallback: (() => Promise<any>) | ReadonlyArray<GraphQLError>
    ) => {
      if (typeof errorsOrMutateCallback !== "function") {

        if (
            (errorsOrMutateCallback as ReadonlyArray<GraphQLError>).filter((error) => {
                return String(error.message).includes('Не указан идентификатор сессии или сессия устарела')
                    || String(error.message).includes('Сессии с таким идентификатором не существует')
                    || String(error.message).includes('Sorry, Can`t refresh sid.')

            }).length == 0
        ) {

            return true;

        } else {
            localStorage.clear();
            history.push("/authorization");

            return false;
        }

        if (!localStorage.getItem("sid")) {
          history.push("/authorization");
          return false;
        }
        client
          .mutate({
            mutation: REFRESH_SID,
            variables: {
              sid: localStorage.getItem("sid")
            }
          })
          .then(res => {
            const sid = (((res || {}).data || {}).refreshSid || {}).sid;
            if (sid) {
              localStorage.setItem("sid", sid);
              if (callback) {
                try {
                  callback();
                } catch (e) {
                  console.error(e);
                }
              }
            }
          })
          .catch(error => {
            if (
              (error.graphQLErrors as ReadonlyArray<
                GraphQLError
              >).filter(({ message }: any) =>
                  String(message).includes("Не указан идентификатор сессии или сессия устарела") ||
                  String(message).includes("Сессии с таким идентификатором не существует") ||
                  String(message).includes("Sorry, Can`t refresh sid.")
              ).length > 0
            ) {
              localStorage.clear();
              history.push("/authorization");
              return false;
            }
          });
      } else if (typeof errorsOrMutateCallback === "function") {
        const func = errorsOrMutateCallback();
        (func as Promise<any>).catch((error: ApolloError) => {
          if (
            error.graphQLErrors.filter(
              ({ message }: any) =>
                String(message).includes("Не указан идентификатор сессии или сессия устарела") ||
                String(message).includes("Сессии с таким идентификатором не существует") ||
                String(message).includes("Sorry, Can`t refresh sid.")
            ).length == 0
          ) {
              return true;
          }
          else {
              localStorage.clear();
              history.push("/authorization");
              return false;
          }
          if (!localStorage.getItem("sid")) {
              localStorage.clear();
              history.push("/authorization");
              return false;
          }
          client
            .mutate({
              mutation: REFRESH_SID,
              variables: {
                sid: localStorage.getItem("sid")
              }
            })
            .then(res => {
              const sid = (((res || {}).data || {}).refreshSid || {}).sid;
              if (sid) {
                localStorage.setItem("sid", sid);
                return func;
              }
            })
            .catch(error => {
              if (
                (error.graphQLErrors as ReadonlyArray<
                  GraphQLError
                >).filter(({ message }: any) =>
                    String(message).includes("Не указан идентификатор сессии или сессия устарела") ||
                    String(message).includes("Сессии с таким идентификатором не существует") ||
                    String(message).includes("Sorry, Can`t refresh sid.")
                ).length > 0
              ) {
                localStorage.clear();
                history.push("/authorization");
                return false;
              } else {
                return true;
              }
            });
        });
      }
    }, // eslint-disable-next-line
    [client]
  );
}
