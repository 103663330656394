// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from "react";
import StoreAgreement from "../../navigation/components/store";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { GeneralContractH3, GeneralContractP } from "./style";

export const GeneralContractHeader = () => {
  const [
    {
      agreement: { agreementState }
    }
  ] = StoreAgreement();

  return (
    <>
      <GeneralContractP>Ген. Договор</GeneralContractP>
      <GeneralContractH3>{agreementState.number}</GeneralContractH3>
    </>
  );
};
