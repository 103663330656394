export interface AgreementState {
  number?: string;
  isn?: string;
  attaches?: any[];
}

export const agreementState: AgreementState = {
  number: "",
  isn: "",
  attaches: []
};
