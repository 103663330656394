import React                                                            from 'react';
import ModalDialog, {ModalHeader, ModalTransition}                      from '@atlaskit/modal-dialog';
import {StyledUploadModalBody, StyledModalHeader, StyledUploadFileArea} from "../styles";
import {StaledLabelWithIcon, StyledIconButton}                          from "../../../components/styles";
import {Button}                                                         from "@atlaskit/button/dist/cjs/components/Button";
import {CloseIcon}                                                      from "../../../icons/ui/Close";
import {ModalBodyRef}                                                   from "../../../utils/ModalBody";
import FilledButton                                                     from "../../../components/button/FilledButton";
import {DownloadIcon}                         from "../../../icons/ui/Download";
import {useLazyQuery, useMutation, useQuery}  from "@apollo/react-hooks";
import {DELETE_BORDEREAUX, UPLOAD_BORDEREAUX} from "../../../utils/mutations";
import {AddFileIcon}                                                    from "../../../icons/ui/AddFile";
import {HandleSessionError}                                             from "../../../utils/apollo-helper";
import StoreUploadModal                                                 from "../../../components/store";
import {GET_API_DATA, GET_DOCS}                                         from "../../../utils/queries";
import StoreAgreement
                                                                        from "../../../components/navigation/components/store";
import {useHistory}                                                     from "react-router";
import {docIsnBordereaux}                                               from "../../../components/@pinkmankit/documentsIsn";
import {GeneralContractHeader} from "../../../components/blocks";

const UploadModalDialog = () => {
  const history = useHistory();

  const [{uploadModal: {uploadModalState}}, {uploadModal: actionsUploadModal}] = StoreUploadModal();
  const toggleUploadModal = (type, isOpen: boolean) => {
    fileSet({
      validity: null,
      file: null
    });
    actionsUploadModal.setState({
      uploadModalState: {
        type: type,
        isOpen,
      },
    })
  };

  const [fileState, fileSet] = React.useState<{
    validity: any
    file: any
  }>({
    validity: null,
    file: null
  });

  const [resultMessageState, resultMessageSet] = React.useState<null | string>(null);

  const [getDocData, {data: dataDocs, error: dataDocsError}] = useLazyQuery(GET_DOCS);
  const {data: dataDownloadFile, loading: loadingDownloadFile, error: errorDownloadFile} = useQuery(GET_API_DATA, {
    variables: {
      name: "templates"
    }
  });
  const docClassIsnBordereaux: string = (((((dataDocs || {}).docs || []).find(doc => doc.isn === docIsnBordereaux) || {}).docs || []).find(doc => doc.argIsn === localStorage.getItem("agreementIsn")) || {}).isn;

  const [uploadBordereaux, {loading, error}] = useMutation(UPLOAD_BORDEREAUX, {
    refetchQueries: [`bordereaux`]
  });
  const [deleteBordereaux, {loading: loadingDeleteBordereaux}] = useMutation(DELETE_BORDEREAUX);

  const handleMutationError = HandleSessionError();

  const handleGetDocsDataError = HandleSessionError(() => {
    getDocData();
  });

  if (dataDocsError) {
    handleGetDocsDataError(dataDocsError.graphQLErrors)
  }

   React.useEffect(() => {
    toggleUploadModal(null, false)
    // eslint-disable-next-line
  }, [history.location.pathname]);

  React.useEffect(() => {
    if (!uploadModalState.isOpen) return;
    getDocData()
    // eslint-disable-next-line
  }, [uploadModalState.isOpen]);

  React.useEffect(() => {
    if (!error || !error.graphQLErrors || !error.graphQLErrors.length || resultMessageState) return;
    const errorMessage = error.graphQLErrors.reduce((prev, next: any) => {
      return prev + " " + (next || {}).message
    }, "");
    resultMessageSet(errorMessage);
    setTimeout(() => resultMessageSet(null), 3000)
    // eslint-disable-next-line
  }, [error]);

  const insertRow = () => {
    if (!fileState.validity.valid) return;

    handleMutationError(() => uploadBordereaux({
      variables: {
        docClassIsn: docClassIsnBordereaux,
        doc: fileState.file
      }
    }).then(() => {
      history.push("/bordereaux");
      toggleUploadModal(null, false)
    }));
  };

  const uploadFileSubmit: any = () => {
    if (!fileState.file || !fileState.validity) return;
    if (uploadModalState.type !== 'refresh') return insertRow();

    handleMutationError(() => deleteBordereaux({
      variables: {
        docClassIsn: docClassIsnBordereaux
      }
    }).then(() => insertRow()))
  };

  const inputChangeHandler = ({target: {validity, files}}) => {
    const file = files[0];
    fileSet({
      validity: validity,
      file: file
    })
  };

  const downloadFile = () => {
    if (loadingDownloadFile) return;
    const parse = (dataDownloadFile||{}).singleton ? JSON.parse(dataDownloadFile.singleton) : undefined;
    if (!parse) return;
    const {path} = (((((parse||{}).files||[]).find(file => ((file||{}).value||{}).type === "upload")||{}).value||{}).file||{})
    if (!path) return;
    window.open(window.location.origin + '/storage/uploads/' + path)
  };

  if (!uploadModalState.isOpen || !dataDocs) return null;
  return (
    <ModalTransition>
      <ModalDialog
        autoFocus={false}
        components={{
          Header: () => (
            <StyledModalHeader>
              <ModalHeader>
                <StyledIconButton>
                  <Button
                    onClick={() => {
                      toggleUploadModal(null, false);
                    }}
                    appearance='subtle-link'>
                    <CloseIcon/>
                  </Button>
                </StyledIconButton>
              </ModalHeader>
            </StyledModalHeader>
          ),
          Body: ModalBodyRef,
        }
        }
        onClose={() => toggleUploadModal(null, false)}>
        <StyledUploadModalBody>
          <>
            <h1>Загрузка бордеро</h1>
            <span>Вы можете загрузить свой файл в формате .xls или скачать наш шаблон и заполнить его.</span>
            { !errorDownloadFile &&
            <label onClick={downloadFile}>
              <DownloadIcon/>
              <span>Скачать шаблон</span>
            </label> }
            <GeneralContractHeader />

            <StyledUploadFileArea>
              <div>{fileState.file ? fileState.file.name : "Перетащите файл .xls в это поле"}</div>
              <input type="file"
                     onChange={inputChangeHandler}
                     id='uploadFile'/>
            </StyledUploadFileArea>

            <StaledLabelWithIcon htmlFor='uploadFile'>
              <AddFileIcon/>
              <span>Прикрепить файл</span>
            </StaledLabelWithIcon>

            <FilledButton
              isLoading={loading || loadingDeleteBordereaux}
              onClick={() => {
                uploadFileSubmit()
              }}
              appearance='primary'
              style={{height: '4rem'}}>
              Загрузить
            </FilledButton>
            {resultMessageState ? <div style={{color: "#D23630"}}>{resultMessageState}</div> : <span>&nbsp;</span>}
          </>
        </StyledUploadModalBody>
      </ModalDialog>
    </ModalTransition>
  );
};
export default UploadModalDialog;
