import {styled, withProps} from '../../../../styled/styled-components';
import {theme}             from '../../../../styled/theme';

export const StyledBordereauxDetailComponentWrapper = withProps<{
  formType?: string;
}>()(styled.div)` 
  ${({formType}) =>
  formType === "add"
    ?
    `
      margin: 0 auto;
    `
    :
    `
    display: flex;
    justify-content: space-between;
    `}
    @media screen and (max-width: 1023px) {
      display: block;
    }
`;


export const StyledBordereauxDetailComponent = withProps<{
  formType?: string;
}>()(styled.div)` 
  width: 68%;
  ${({formType}) =>
  formType === "add"
    ? `
      margin: 0 auto;
  `
    : ''}
  @media screen and (max-width: 1023px) {
    width: 100%;
  }
`;

export const StyledButtonsWrapper = withProps<{
  type?: string;
}>()(styled.div)` 
 max-width: 68%;
  ${({type}) =>
  type === "add"
    ?
    `
    margin: 0 auto;
  `
    : ""}
 @media screen and (max-width: 1023px) {
  max-width: 100%;
}
`;

export const StyledSaveButtons = styled.div` 
 position: sticky;
 top: 6rem;
`;
export const StyledAltPlace = styled.div` 
  display: flex;
  color: #D23630;
  margin-bottom: 1rem;
  span {
    white-space: normal;
  }
`;

export const StyledBordereauxDetailHeader = styled.div` 
  h1 {
    margin: 1.5rem 0 1.5rem 0;
  }
  @media screen and (max-width: 1023px) {
    margin-bottom: 2rem;
  }
`;

export const StyledBordereauxDetailForm = styled.div` 
  background: #FFFFFF;
  box-shadow: 0px 2px 20px rgba(0, 61, 129, 0.1);
  width: calc(100% - 6rem);
  padding: 3rem;
  margin-bottom: 2rem;
  
  
  div[class="formHeader"] {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 2rem;
  }  
  
  div[class="formHeader"] div {
    margin-right: 6rem;
  }
    
  div[class="formHeader"] h2 {
    margin-top: 1rem;
  }
  
  h1 {
    margin-top: 6rem;
    text-transform: capitalize;
  }
  
  h1:first-child {
    margin: 0 0 2rem 0;
  }
  
  input {
    font-size: 1.4rem;
    height: calc(6rem - 2px);
  }
     
  div {
    border-width: 1px;
    border-color: #CCCCCC;
    border-radius: 0;
  } 
  
  div[spacing="cosy"] {
    padding: 0;
  }  
  
  div[class$="control"] {
    background: #FFFFFF;
    height: 6rem;
  } 
  div[class$="control"] > div > div {
    line-height: unset;
  } 
  
  div[role="presentation"] input {
    font-size: 1.4rem;
    height: auto;
  }
    
  div[class^="Field__FieldWrapper"] {
    margin-top: 3rem;
  }  
  
  div[class="transportTypeArea"] {
    margin-top: 4rem;
  }  
  div[class="transportTypeArea"] > div div {
    display: inline-flex;
    margin: 2rem 3rem 0 0;
  }
  
  div[class="lastCheck"] {
    margin-top: 2rem;
    border-top: 1px solid #CCCCCC;
  }
  
  div[class="lastCheck"] > div {
    color: black;
    padding: 2rem 0 2rem 0;
    border-bottom: 1px solid #CCCCCC;
  }
  
  @media screen and (max-width: 1023px) {
    width: calc(100% - 6rem);
  }
`;

export const StyledSelect = withProps<{
  isInvalid?: boolean;
}>()(styled.div)` 
  ${({isInvalid}) =>
  isInvalid
    ?
    `
    div[class$="control"] {
      border-color: #D23630!important;
    } 
    `
    :
    ``
}
    
  div {
    border-width: 1px;
    border-radius: 0;
  } 
  div[class$="control"] {
    border-color: #CCCCCC;
    background: #FFFFFF;
    height: 6rem;
    transition: unset;
  }  
   div[class$="placeholder"] {
    white-space: nowrap;
    text-overflow: ellipsis;
  } 
  div[class$="control"]:hover  {
    border-color: ${theme().colours.lightBlue};
    height: 6rem;
  } 
  
  div[class$="control"]:hover + div[class$="menu"]  {
    border-color: ${theme().colours.lightBlue};
  } 
  
  div[class$="control"] > div > div {
    color: black;
    line-height: unset;
  }  
  
  div[class$="control"] > div {
    padding-left: 1.5rem!important;
  } 
  
  div[class$="menu"] {
    margin: -1px 0 0 0;
    box-shadow: unset;
    border: 1px solid #CCCCCC;
    border-top: 0;
  } 
  
  div[class$="menu"] > div {
    min-height: 5rem;
    padding: 0;
  } 
  div[class$="option"] {
    border-top: 1px solid ${theme().colours.lightishGray};
    background: #FFFFFF;
    display: flex;
    align-items: center;
    height: 5rem;
    color: black;
  }
  div[class$="option"]:hover {
    background: #EDF1F6;
  }
  input {
    font-size: 1.4rem;
    height: auto;
  }
`;

export const StyledHelperMessage = styled.div`
  display: inline-flex;
  font-size: 1.2rem;
  padding: 1rem 0; 
  color: black;
  opacity: 0.4; 
  svg {
    opacity: 0.4; 
    margin-right: 0.7rem;
  }
`;

export const StyledTextArea = withProps<{
  isInvalid?: boolean;
}>()(styled.textarea)` 
  font-size: Verdana;
  color: black;
  font-size: 1.4rem;
  outline: none;
  min-width: calc(100% - 3rem);
  max-width: calc(100% - 3rem);
  max-height: 50vh;
  padding: 1.5rem;
  min-height: 12rem;
  border: 1px solid #CCCCCC;
  
  :focus, :hover{
    border-color: ${theme().colours.lightBlue};
  }
  
  ${({isInvalid}) =>
  isInvalid
  ?
  `
    border-color: #D23630!important;
  `
  :
  ``
  }
`;
