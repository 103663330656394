import React                               from 'react';
import {StyledLoader, StyledLoaderWrapper} from './styles';
import loader                              from "./loader.png"


export const Loader = () => (
  <StyledLoaderWrapper>
    <StyledLoader>
      <img src={loader} alt="preloader"/>
    </StyledLoader>
  </StyledLoaderWrapper>
);
export default Loader as any;
