import React                                  from 'react';
import {
  Link,
  Route,
  Switch,
  useHistory,
  useLocation
}                                             from 'react-router-dom';
import {
  NavigationProvider,
  ThemeProvider,
  LayoutManager
}                                             from '@atlaskit/navigation-next';
import {ApolloProvider}                       from '@apollo/react-common';
import Loadable                               from 'react-loadable';
import {client}                               from './Apollo';
import Loader                                 from '../components/loader';
import AtlassianNavigation                    from './navigation/components/AtlassianNavigation';
import '../styled/styles';
import StoreNavigation                        from '../components/navigation/store';
import {
  ContentStyle,
  StyledContactsLeftMenu,
  StyledLeftMenuItem, StyledLeftMenuItems,
  StyledLeftMenuLogo,
  StyledLeftMenuWrapper, StyledMobileDarkBackground,
  StyledNavigation
}                                             from './styles';
import Authorization                          from "../pages/auth/Authorization";
import {ThemeProvider as StyledThemeProvider} from 'styled-components';
import {theme}                                from '../styled/theme';
import {AtlaskitThemeProvider}                from '@atlaskit/theme';
import {LogoDesktop}                          from "../icons/logo/LogoDesktop";
import {ContactPersonIcon}                    from "../icons/ui/ContactPerson";
import {PhoneIcon}                            from "../icons/ui/Phone";
import useResize                              from "use-resize"
import UploadModalDialog                      from "../pages/transportLog/components/UploadFileModal";
import {Modal}                                from "./modal";
import ContactsModalDialog                    from "./contactsModal";
import { useQuery } from '@apollo/react-hooks';
import { GET_API_DATA } from '../utils/queries';

const WHITE_BACKGROUND = ["/reporting", "/lossLog", "/", "/bordereaux", "/accounts", "/authorization"];

const RootRedirect = Loadable({
  loader: () => import('../pages/rootRedirect'),
  loading: Loader,
  delay: 200,
});

const App = () => {
  const pageSize = useResize();
  const history = useHistory();
  const {pathname} = useLocation();
  const [{navigation: {navigationState}}, {navigation: actionsNavigation}] = StoreNavigation();
  const toggleNavigation = () => actionsNavigation.setState({
    navigationState: {
      ...navigationState,
      isOpen: !navigationState.isOpen
    }
  });
  const closeNavigation = () => actionsNavigation.setState({
    navigationState: {
      ...navigationState,
      isOpen: false
    }
  });

  const [contactsModalState, contactsModalSet] = React.useState(false);
  const contactsModalCloseHandler = () => {
    contactsModalSet(!contactsModalState);
  };

  React.useEffect(() => {
    if (!(pageSize.width < 768)) return;
    closeNavigation();
    // eslint-disable-next-line
  }, [history.location.pathname]);

  const LeftMenuItemsDesktop = () => (
    <>
      <StyledLeftMenuItem selected={pathname === "/"}>
        <Link to={"/"}>Журнал перевозок</Link>
      </StyledLeftMenuItem>

      <StyledLeftMenuItem selected={pathname === "/bordereaux"}>
        <Link to={"/bordereaux"}>Бордеро</Link>
      </StyledLeftMenuItem>

      <StyledLeftMenuItem selected={pathname === "/accounts"}>
        <Link to={"/accounts"}>Счета</Link>
      </StyledLeftMenuItem>

      <StyledLeftMenuItem selected={pathname === "/lossLog"}>
        <Link to={"/lossLog"}>Журнал убытков</Link>
      </StyledLeftMenuItem>

      <StyledLeftMenuItem selected={pathname === "/reporting"}>
        <Link to={"/reporting"}>Отчетность</Link>
      </StyledLeftMenuItem>

    </>
  );
  const LeftMenuItemsMobile = () => (
    <>
      <StyledLeftMenuItem selected={pathname === "/profile"}>
        <Link to={"/profile"}>Профиль</Link>
      </StyledLeftMenuItem>

      <StyledLeftMenuItem selected={false}>
        <button onClick={() => {
          localStorage.removeItem('sid');
          history.push('/authorization');
        }}>Выйти
        </button>
      </StyledLeftMenuItem>
    </>
  );

  const ContainerNavigation = () => {
    const {data} = useQuery(GET_API_DATA, {
      variables: {
        name: "auth"
      }
    });
    const authDataFromApi = data ? JSON.parse(data.singleton) : undefined;
    return (
    <StyledLeftMenuWrapper>
      <StyledLeftMenuItems>
        <StyledLeftMenuLogo>
          <Link to="/"><LogoDesktop/></Link>
        </StyledLeftMenuLogo>

        {pageSize.width < 768 ?
          <>
            <LeftMenuItemsDesktop/>
            <LeftMenuItemsMobile/>
          </>
          :
          <LeftMenuItemsDesktop/>
        }
      </StyledLeftMenuItems>
      <StyledContactsLeftMenu>
        <div onClick={() => contactsModalSet(!contactsModalState)}>
          <span><ContactPersonIcon/></span>
          Контакты куратора
        </div>
        {(authDataFromApi||{}).phone &&
        <div>
          <span><PhoneIcon/></span>
          <a href={`tel:${(authDataFromApi||{}).phone}`}>{(authDataFromApi||{}).phone || ""}</a>
        </div>
        }
        {(authDataFromApi||{}).copyright && <p>{(authDataFromApi||{}).copyright}</p>}
      </StyledContactsLeftMenu>
    </StyledLeftMenuWrapper>
  )};

  return (
    <>
      <ApolloProvider client={client}>
        <StyledThemeProvider theme={theme}>
          <AtlaskitThemeProvider mode="light">
            <StyledNavigation leftMenuIsOpen={navigationState.isOpen} isAuth={pathname === "/authorization"}>
              <NavigationProvider
                initialUIController={{
                  isResizeDisabled: true,
                  productNavWidth: '26rem'
                }}
                isDebugEnabled={true}>
                <ThemeProvider theme={theme => ({...theme})}>
                  <LayoutManager
                    containerNavigation={ContainerNavigation}
                    productNavigation={() => null}
                    experimental_flyoutOnHover={false}
                    experimental_alternateFlyoutBehaviour={false}
                    experimental_fullWidthFlyout={false}
                    experimental_hideNavVisuallyOnCollapse={false}
                    experimental_horizontalGlobalNav={true}
                    globalNavigation={() => pathname === "/authorization" ? null : (<AtlassianNavigation/>)}
                    showContextualNavigation={pathname === "/authorization" ? false : navigationState.isOpen}
                  >
                    <Modal>
                      <StyledMobileDarkBackground isOpen={navigationState.isOpen} onClick={toggleNavigation}/>
                    </Modal>

                    <ContentStyle isAuth={pathname === "/authorization"}
                                  isWhiteBackground={WHITE_BACKGROUND.includes(history.location.pathname)}>
                      <>
                        <Switch>
                          <Route exact={true} path="/authorization" component={Authorization}/>
                          <Route component={RootRedirect}/>
                        </Switch>

                        <UploadModalDialog/>
                        {contactsModalState && <ContactsModalDialog onClose={contactsModalCloseHandler}/>}
                      </>
                    </ContentStyle>
                  </LayoutManager>
                </ThemeProvider>
              </NavigationProvider>
            </StyledNavigation>
          </AtlaskitThemeProvider>
        </StyledThemeProvider>
      </ApolloProvider>
    </>
  );
};
export default App;
