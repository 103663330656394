import { injectGlobal } from "styled-components";
import baseStyles from "../components/@pinkmankit/css-reset/base";
import adaptiveFontSize from "../components/@pinkmankit/css-reset/adaptiveFontSize";
import browserFixesStyles from "@atlaskit/css-reset/browser-fixes";
import resetStyles from "@atlaskit/css-reset/reset";
import tableStyles from "@atlaskit/css-reset/tables";
import utilStyles from "@atlaskit/css-reset/utils";

injectGlobal`
${resetStyles}
${baseStyles}
${tableStyles}
${browserFixesStyles}
${utilStyles}
${adaptiveFontSize}
body {
  font: 0.8125rem;
  color: #3d556b;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

div[data-react-beautiful-dnd-droppable], div[data-react-beautiful-dnd-draggable] {
    outline: none;
}
div[class^="Field__"] div[class$="menu"] {
    z-index: 10;
}
`;
