import {
  AtlassianNavigation
}                                                                from '@atlaskit/atlassian-navigation';
import React                                                     from 'react';
import {Link}                                                    from 'react-router-dom';
import StoreNavigation                                           from '../store';
import StoreAgreement                                            from './store';
import Button                                                    from '@atlaskit/button/dist/cjs/components/Button';
import {QuestionIcon}                                            from '../../../icons/ui/Question';
import {LogOutIcon}                                              from '../../../icons/ui/LogOut';
import {BurgerIcon}                                              from '../../../icons/ui/Burger';
import {PersonIcon}                                              from '../../../icons/ui/Person';
import {StyledIconButton, StyledHeaderButtons, StyledMainHeader} from '../../styles';
import useResize                                                 from 'use-resize';
import {LogoMobile}                                              from '../../../icons/logo/LogoMobile';
import {useHistory}                                              from 'react-router-dom';
import {useLazyQuery}                                            from "@apollo/react-hooks";
import {GET_AGREEMENTS}                                          from "../../../utils/queries";
import {HandleSessionError}                                      from "../../../utils/apollo-helper";
import DropdownMenu, {DropdownItemGroup, DropdownItem}           from '@atlaskit/dropdown-menu';
import {DownArrowIcon}                                           from "../../../icons/ui/DownArrow";
import {StyledAgreementsDropDown}                                from "../../../pages/transportLog/styles";

const AppNavigationComponent = () => {
  const history = useHistory();
  const pageSize = useResize();

  const [{navigation: {navigationState}}, {navigation: actionsNavigation}] = StoreNavigation();
  const toggleNavigation = () => actionsNavigation.setState({
    navigationState: {
      ...navigationState,
      isOpen: !navigationState.isOpen
    }
  });

  const [{agreement: {agreementState}}, {agreement: actionAgreement}] = StoreAgreement();
  const changeAgreement = (number, isn, attaches) => {
    localStorage.setItem("agreementIsn", isn);
    actionAgreement.setState({
      agreementState: {
        number: number,
        isn: isn,
        attaches
      }
    })
  };

  const [getData, {data, error}] = useLazyQuery(GET_AGREEMENTS);

  const handleGetBordereauxError = HandleSessionError(() => {
    getData()
  });

  if (error) {
    handleGetBordereauxError(error.graphQLErrors)
  }

  React.useEffect(() => {
    if (!data) {
      return getData()
    }
    const {number, isn, attaches} = (((data || {}).agreements || [])[0] || {});
    if (localStorage.getItem("agreementIsn")) {
      const agreement = data.agreements.find(agreement => agreement.isn === localStorage.getItem("agreementIsn"));
      if (!agreement) {
        changeAgreement(
          number,
          isn,
          attaches,
        );
      } else {
        changeAgreement(
          agreement.number,
          agreement.isn,
          agreement.attaches
        );
      }
    } else {
      changeAgreement(
        number,
        isn,
        attaches
      );
    }
    // eslint-disable-next-line
  }, [data]);

  const ToggleNavigationButton = () => (
    <div>
      <Button
        appearance="subtle-link"
        iconBefore={
          <StyledIconButton>
            <BurgerIcon/>
          </StyledIconButton>
        }
        onClick={toggleNavigation}
      />
      {pageSize.width < 768 ?
        <Button
          onClick={() => {
            history.push('/');
          }}
          appearance="subtle-link">
          <LogoMobile/>
        </Button>
        :
        null
      }
    </div>
  );
  const ProfileComponent = () => (
    <>
      {data ?
        <StyledAgreementsDropDown>
          <DropdownMenu
            position="bottom right"
            trigger={
              <>
                <span>Генеральный договор:&nbsp;</span>
                <span>
                {agreementState.number}
                  &nbsp;
                  <DownArrowIcon/>
              </span>
              </>
            }
          >
            <DropdownItemGroup>
              {data.agreements.map(agreement => (
                <DropdownItem
                  key={agreement.isn}
                  onClick={() => {
                    if (agreementState.isn === agreement.isn) return;
                    changeAgreement(agreement.number, agreement.isn, agreement.attaches);
                  }}>
                  {agreement.number}
                </DropdownItem>
              ))}
            </DropdownItemGroup>
          </DropdownMenu>
        </StyledAgreementsDropDown>
        :
        null
      }
      {pageSize.width < 768 ?
        null
        :
        <>
          <StyledHeaderButtons>
            <Link to='/profile'>
              <StyledIconButton>
                <Button appearance='subtle-link'>
                  <PersonIcon/>
                </Button>
              </StyledIconButton>
            </Link>
            <Link to='/help'>
              <StyledIconButton>
                <Button appearance='subtle-link'>
                  <QuestionIcon/>
                </Button>
              </StyledIconButton>
            </Link>
            <div onClick={() => {
              localStorage.removeItem('sid');
              history.push('/authorization');
            }}>
              <StyledIconButton>
                <Button appearance='subtle-link'>
                  <LogOutIcon/>
                </Button>
              </StyledIconButton>
            </div>
          </StyledHeaderButtons>
        </>
      }
    </>
  );
  return (
    <StyledMainHeader>
      <AtlassianNavigation
        primaryItems={[]}
        renderProductHome={ToggleNavigationButton}
        renderProfile={ProfileComponent}
      />
    </StyledMainHeader>
  );
};
export default AppNavigationComponent;
