import React                                            from 'react';
import {useHistory}                                     from 'react-router-dom';
import {StyledLoginFormComponent, StyledPasswordButton} from '../styles'
import FilledButton                                     from "../../../components/button/FilledButton";
import Form, {Field}                                    from '@atlaskit/form';
import PasswordRecovery                                 from "./PasswordRecovery";
import {useMutation, useQuery}                          from "@apollo/react-hooks";
import {AUTHORIZATION}                                  from "../../../utils/mutations";
import {StyledPlaceForError}                            from "../../../components/styles";
import CustomTextField                                  from "../../../components/@pinkmankit/textField";
import { SmartCaptcha  }                        from '@yandex/smart-captcha';
import {GET_PUB_KEY} from "../../../utils/queries";


const {JSEncrypt} = require('jsencrypt');
const encrypt = new JSEncrypt();


const LoginForm = () => {
  const [passwordRecoveryState, passwordRecoverySet] = React.useState(false);
  const history = useHistory();

  const passwordRecoverySwitch = () => {
    passwordRecoverySet(!passwordRecoveryState)
  };

  const { loading, data } = useQuery(GET_PUB_KEY);
    if (!loading && data) {
        localStorage.setItem('pub_key', data.pubKey[0])
        encrypt.setPublicKey(data.pubKey[0]);
    }

  const [authorization, {error}] = useMutation(AUTHORIZATION);

    const [token, setToken] = React.useState('');
    const [isSubmitBtnDisabled, setIsSubmitBtnDisabled] = React.useState(true);

  const loginFormSubmit = (formData: { username: string, password: string }) => {
    try {
      return authorization({
        variables: {
          login: formData.username,
          password: encrypt.encrypt(formData.password),
          token: token
        }
      }).then(data => {
        localStorage.setItem('sid', data.data.auth.sid);
        localStorage.setItem('user-isn', data.data.auth.me.isn);
        history.push('/');
      })
    } catch (e) {
    }
  };


  const siteKey = process.env.REACT_APP_CAPTCHA_SITE_KEY;

  const onCaptchaReady = (token) => {
      setToken(token);
      setIsSubmitBtnDisabled(false);
  }


    return (
    <StyledLoginFormComponent>

      {!passwordRecoveryState ?
        <>
          <h1>Авторизация</h1>
          <Form onSubmit={loginFormSubmit}>
            {({formProps, submitting}) => (
              <form {...formProps}>
                <Field name="username" defaultValue="" isRequired>
                  {({fieldProps}) =>
                    <CustomTextField {...fieldProps} label="Логин"/>
                  }
                </Field>
                <Field name="password" defaultValue="" isRequired validate={(val) => val.length < 6}>
                  {({fieldProps}) =>
                    <CustomTextField {...fieldProps} type='password' label="Пароль"/>
                  }
                </Field>

                  <SmartCaptcha  sitekey={siteKey} onSuccess={onCaptchaReady} />

                <FilledButton isDisabled={isSubmitBtnDisabled} appearance='primary' type="submit" isLoading={submitting}>
                  Войти
                </FilledButton>
                {error && error.graphQLErrors && error.graphQLErrors.map((error: any, index) => (
                  <StyledPlaceForError>
                    <div key={`error-${index}`}>{error.message}</div>
                  </StyledPlaceForError>
                ))}
              </form>
            )}
          </Form>
          <StyledPasswordButton>
            <FilledButton appearance='link' onClick={passwordRecoverySwitch}>
              Забыли пароль?
            </FilledButton>
          </StyledPasswordButton>
        </>
        :
        <PasswordRecovery recoverySwitch={passwordRecoverySwitch}/>
      }

    </StyledLoginFormComponent>
  );
};
export default LoginForm;
