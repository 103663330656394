import React                              from 'react';
import {StyledTabs, StyledPasswordButton} from '../styles';
import FilledButton
                                          from '../../../components/button/FilledButton';
import Form, {Field}                      from '@atlaskit/form';
import Tabs                               from '@atlaskit/tabs';
import {useMutation}                      from '@apollo/react-hooks';
import {RECOVERY}                         from '../../../utils/mutations';
import {isValidPhoneNumber}               from 'react-phone-number-input';
import CustomTextField                    from "../../../components/@pinkmankit/textField";
import {StyledPlaceForError}              from "../../../components/styles";

const PasswordRecovery = ({recoverySwitch}) => {
  const [finalStageEmailState, finalStageEmailSet] = React.useState({
    isOpen: false,
    value: null,
    login: null
  });
  const [finalStagePhoneState, finalStagePhoneSet] = React.useState({
    isOpen: false,
    value: null,
    login: null
  });
  const [tabState, tabSet] = React.useState({
    selected: 0
  });
  const [recovery, {error, loading}] = useMutation(RECOVERY);
  const getPasswordHandler = (data) => {
    if (!data.login) return;
    return recovery({
      variables: {
        contact: data.phone || data.email,
        login: data.login
      }
    }).then(() => {
      if (data.phone) {
        finalStagePhoneSet({
          isOpen: true,
          value: data.phone,
          login: data.login
        });
      } else {
        tabSet({
          selected: 1
        });
        finalStageEmailSet({
          isOpen: true,
          value: data.email,
          login: data.login
        });
      }
    }).catch(error => {
      console.log(error.graphQLErrors);
    });
  };
  const RecoveryForPhone = () => {
    return (
      <Form onSubmit={getPasswordHandler}>
        {({formProps, submitting}) => (
          <form {...formProps}>
            <Field name="login" defaultValue="" isRequired>
              {({fieldProps}) =>
                <CustomTextField {...fieldProps} label="Введите Логин"/>
              }
            </Field>
            <Field name="phone" type="number" defaultValue="+7" isRequired
                   validate={value => isValidPhoneNumber(value) ? undefined : 'Неверный номер телефона'}>
              {({fieldProps, error}) => (
                <>
                  <CustomTextField {...fieldProps}
                                   onChange={({target}) => {
                                     const value = (target as any).value.replace(/[^0-9]+/g, '');
                                     fieldProps.onChange(`+${value.charAt(0) !== '7' ? '7' : ''}${value}`);
                                   }}
                                   label="Введите Телефон"/>
                  {error &&
                  <StyledPlaceForError>
                      <div>{error}</div>
                  </StyledPlaceForError>
                  }
                </>
              )}
            </Field>
            <FilledButton appearance='primary' type="submit" isLoading={submitting || loading}>Получить
              пароль</FilledButton>
            {error && error.graphQLErrors && error.graphQLErrors.map((error: any, index) => (
              <StyledPlaceForError>
                <div key={`error-${index}`}>{error.message}</div>
              </StyledPlaceForError>
            ))}
          </form>
        )}
      </Form>
    );
  };
  const RecoveryForEmail = () => {
    return (
      <Form onSubmit={getPasswordHandler}>
        {({formProps, submitting}) => (
          <form {...formProps}>
            <Field name="login" defaultValue="" isRequired>
              {({fieldProps}) =>
                <CustomTextField {...fieldProps} label="Введите Логин"/>
              }
            </Field>
            <Field name="email" defaultValue="" isRequired>
              {({fieldProps}) =>
                <CustomTextField {...fieldProps} type="email" label="Введите E-mail"/>
              }
            </Field>
            <FilledButton appearance='primary' type="submit" isLoading={submitting || loading}>Получить
              пароль</FilledButton>
            {error && error.graphQLErrors && error.graphQLErrors.map((error: any, index) => (
              <StyledPlaceForError>
                <div key={`error-${index}`}>{error.message}</div>
              </StyledPlaceForError>
            ))}
          </form>
        )}
      </Form>
    );
  };
  const EnterPasswordPhone = () => {
    return (
      <>
        <span style={{fontSize: '1.4rem'}}>Мы отправили пароль на номер {finalStagePhoneState.value}</span>
        <StyledPasswordButton>
          <FilledButton appearance='link' onClick={recoverySwitch}>
            Войти
          </FilledButton>
        </StyledPasswordButton>
      </>
    );
  };
  const EnterPasswordEmail = () => {
    return (
      <>
        <span style={{fontSize: '1.4rem'}}>Мы отправили пароль на адрес {finalStageEmailState.value}</span>
        <StyledPasswordButton>
          <FilledButton appearance='link' onClick={recoverySwitch}>
            Войти
          </FilledButton>
        </StyledPasswordButton>
      </>
    );
  };
  const tabs = [
    {label: 'Телефон', content: <RecoveryForPhone/>},
    {label: 'E-mail', content: <RecoveryForEmail/>}
  ];
  return (
    <>
      <h1>Восстановление пароля</h1>
      {!finalStagePhoneState.isOpen && !finalStageEmailState.isOpen ?
        <>
          <StyledTabs>
            <Tabs
              selected={tabs[tabState.selected]}
              tabs={tabs}
              onSelect={(_tab, index) => tabSet({
                selected: index
              })}
            />
          </StyledTabs>
          <StyledPasswordButton>
            <FilledButton appearance='link' onClick={recoverySwitch}>
              Уже помню! Войти
            </FilledButton>
          </StyledPasswordButton>
        </>
        :
        null
      }
      {finalStageEmailState.isOpen ?
        <EnterPasswordEmail/>
        :
        null
      }
      {finalStagePhoneState.isOpen ?
        <EnterPasswordPhone/>
        :
        null
      }
    </>
  );
};
export default PasswordRecovery;
