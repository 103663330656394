import React         from 'react';
import {ButtonProps} from '@atlaskit/button';
import Button        from '@atlaskit/button/dist/cjs/components/Button';
import {extract}     from '../../utils/extract';
import {theme}       from '../../styled/theme';

export const FilledButton = (props: ButtonProps) => {
  return (
    <Button
      {...props}
      theme={(currentTheme, themeProps) => {
        const {buttonStyles, ...rest} = currentTheme(themeProps);
        return {
          buttonStyles: {
            ...buttonStyles,
            ...extract({
              primary: {
                borderRadius: 'none',
                width: '100%',
                justifyContent: 'space-around',
                height: '5rem',
                fontSize: '1.4rem',
                background: props.isDisabled ? `${theme().colours.gray}` :
                  {
                    default: `${theme().colours.lightBlue}`,
                    hover: `${theme().colours.hoverBlue}`,
                    active: `${theme().colours.clickBlue}`,
                    focused: `${theme().colours.clickBlue}`
                  },
                color: `${theme().colours.white}`,
              },
              link: {
                fontSize: '1.4rem',
                textDecoration: 'underline',
                color: {
                  default: `${theme().colours.blue}`,
                  hover: `${theme().colours.hoverBlue}`,
                  active: `${theme().colours.clickBlue}`,
                  focused: `${theme().colours.clickBlue}`
                }
              }
            }, themeProps)
          },
          ...rest
        };
      }}
    />
  );
};

export default FilledButton;
