// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import { styled } from "../../../styled/styled-components";

export const GeneralContractP = styled.p`
  opacity: 0.5;
  color: black;
  padding-bottom: 1rem;
`;

export const GeneralContractH3 = styled.h3`
  color: black;
  font-weight: normal;
  margin: 0 0 3rem 0;
`;
