import * as styledComponents from "styled-components";
import { ThemedStyledComponentsModule } from "styled-components";
import { ThemeInterfaceLight } from "./theme";

const {
  default: styled,
  css,
  keyframes
} = styledComponents as ThemedStyledComponentsModule<ThemeInterfaceLight>;
export { css, keyframes };
export default styled;
export { styled };
export const withProps = <U>() => <P, T, O>(
  fn: styledComponents.ThemedStyledFunction<P, T, O>
) => fn as styledComponents.ThemedStyledFunction<P & U, T, O & U>;
