import React                   from "react";
import {StylesLoginFormLayout} from "../styles";
import {LogoDesktop}           from "../../../icons/logo/LogoDesktop";
import {Link}                  from "react-router-dom";
import {PhoneIcon}             from "../../../icons/ui/Phone";
import {useQuery}              from "@apollo/react-hooks";
import {GET_API_DATA}          from "../../../utils/queries";
import {ErrorMessage}          from '@atlaskit/form';
import LoadingError from "../../../components/page/LoadingError";


const LoginFormLayout = (props) => {

  const {data, loading} = useQuery(GET_API_DATA, {
    variables: {
      name: "auth"
    }
  });

  if (loading) return null;
  const authDataFromApi = data ? JSON.parse(data.singleton) : undefined;
  if (authDataFromApi.error) return <LoadingError/>;

  return (
    <StylesLoginFormLayout>

      <Link to='/'>
        <LogoDesktop/>
      </Link>

      <div className='content'>
        {props.children}
      </div>

      <div className='footer'>
        {(authDataFromApi || {}).phone &&
        <span>
          <PhoneIcon/>
          <a href={`tel:${authDataFromApi.phone}`}>{authDataFromApi.phone}</a>
        </span>}
        {(authDataFromApi||{}).copyright && <p>{authDataFromApi.copyright}</p>}
      </div>
    </StylesLoginFormLayout>
  );
};
export default LoginFormLayout;
