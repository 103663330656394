import evaluateInner from "@atlaskit/css-reset/dist/esm/utils/evaluate-inner";

export default evaluateInner`
html {
  font-size: 10px;
}

html {
  color: black;
  // overflow: auto;
}

body input::-webkit-outer-spin-button,
body input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
body input[type=number] {
  -moz-appearance:textfield;
}

// html body::-webkit-scrollbar {
//   width: 3px;
// }
// 
// html body::-webkit-scrollbar-track {
//   background: transparent;
// }
// 
// html body::-webkit-scrollbar-thumb {
//   border-radius: 10px;
//   background-color: #003D81;
// }

@media screen and (max-width: 767px) {
  html {
    font-size: calc(10px + 13 * (100vw - 375px) / 447);
  }
  h1 {
    font-size: 2rem;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  html {
    font-size: calc(9px + 4 * (100vw - 768px) / 255);
    }
}

@media (min-width: 1024px) and (max-width: 1220px) {
  html {
    font-size: calc(8px + 2 * (100vw - 1024px) / 336);
}
}

@media (min-width: 1221px) and (max-width: 1440px) {
  html {
  font-size:10px
}
}

@media (min-width: 1440px) {
  html {
    font-size: 10px;
}
}


_:default:not(:root:root),
html {
  animation: webkitfix 1s forwards infinite;
}
@-webkit-keyframes webkitfix {
  100% {
    z-index: auto;
  }
}

html body {
  font-size: 1.4rem;
  font-family: Verdana;
}
`;
