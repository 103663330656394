import React                                          from 'react';
import ModalDialog, {ModalHeader, ModalTransition}    from '@atlaskit/modal-dialog';
import {StyledModalHeader}                            from "../../pages/transportLog/styles";
import {StyledIconButton}                             from "../styles";
import {Button}                                       from "@atlaskit/button/dist/cjs/components/Button";
import {CloseIcon}                                    from "../../icons/ui/Close";
import {ModalBodyRef}                                 from "../../utils/ModalBody";
import {useLazyQuery}                                 from "@apollo/react-hooks";
import {GET_AGREEMENTS}                               from "../../utils/queries";
import {HandleSessionError}                           from "../../utils/apollo-helper";
import {StyledSelect}                                 from "../../pages/bordereaux/components/detailBordereaux/styles";
import Select                                         from '@atlaskit/select';
import StoreAgreement                                 from "../navigation/components/store";
import {StyledContactsModal, StyledContactsModalBody} from "./styles";

const ContactsModalDialog = ({onClose}) => {
  const [{agreement: {agreementState}}, {agreement: actionAgreement}] = StoreAgreement();
  const changeAgreement = (number, isn) => {
    localStorage.setItem("agreementIsn", isn);
    actionAgreement.setState({
      agreementState: {
        number: number,
        isn: isn
      }
    })
  };

  const [getData, {data, error}] = useLazyQuery(GET_AGREEMENTS);

  const handleGetAgreementsError = HandleSessionError(() => {
    getData()
  });

  if (error) {
    handleGetAgreementsError(error.graphQLErrors)
  }

  React.useEffect(() => {
    if (!data) {
      return getData()
    }
    // eslint-disable-next-line
  }, [data]);

  const getCuratorName = () => {
    const agreement = data.agreements.find(agreement => agreement.isn === agreementState.isn);
    return {
      name: `${(agreement || {}).surName || ""} ${(agreement || {}).firstName || ""} ${(agreement || {}).middleName || ""}`,
      phone: (agreement || {}).phone || "",
      email: (agreement || {}).email || ""
    }
  };

  if (!data || !data.agreements) return null;

  return (
    <ModalTransition>
      <ModalDialog
        autoFocus={false}
        components={{
          Header: () => (
            <StyledModalHeader>
              <ModalHeader>
                <StyledIconButton>
                  <Button
                    onClick={() => {
                      onClose()
                    }}
                    appearance='subtle-link'>
                    <CloseIcon/>
                  </Button>
                </StyledIconButton>
              </ModalHeader>
            </StyledModalHeader>
          ),
          Body: ModalBodyRef,
          Container: (props) => <StyledContactsModal>{props.children}</StyledContactsModal>
        }
        }
        onClose={() => onClose()}>

        <StyledContactsModalBody>
          <StyledSelect>
            <Select
              value={{label: agreementState.number, value: agreementState.number}}
              options={((data || {}).agreements || []).map(agreement => {
                return {label: agreement.number, value: agreement.number}
              })}
              onChange={(dataSelect: any) => {
                const isn = data.agreements.find(agreement => agreement.number === dataSelect.label).isn;
                changeAgreement(dataSelect.label, isn);
              }}
              placeholder="Ген.договор"
            />
            <p>Ваш куратор</p>
            <h1>{(() => getCuratorName().name)()}</h1>
            <p>Телефон</p>
            <h3>{(() => getCuratorName().phone)()}</h3>
            <p>E-mail</p>
            <h3>{(() => getCuratorName().email)()}</h3>
            <p>с 09:00 до 18:00 по московскому времени, пн-пт</p>
          </StyledSelect>
        </StyledContactsModalBody>

      </ModalDialog>
    </ModalTransition>
  );
};
export default ContactsModalDialog;
