import {styled, withProps} from '../../styled/styled-components';
import {theme}             from '../../styled/theme';

export const StyledPanel = styled.div` 
  position: relative;
  > div {
    margin-bottom: 2px;
  }
  > div > div {
    background: #EDF1F9;
    height: 6rem;
    margin: 0;
    padding: 0;
    border-radius: 0;
    position: unset;
  }

  > div > div > div > button {
    display: none;
  }
  > div > div > span {
      color: black;
      font-size: 1.4rem;
      font-weight: bold;
      text-transform: capitalize; 
      padding: 0 3rem 0 3rem;
  }
    
  div[class="panelStatusIcon"] {
    pointer-events: none;
    position: absolute;
    right: 3rem;
    top: 2.25rem;
  }
  > div > div:hover > span {
    color: ${theme().colours.blue};
  }
  div[class="panelStatusIcon"] {
    cursor: pointer;
  }
`;

export const StyledHomeHeader = styled.div` 
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 3rem 0;
  
  div[class$="homeHeaderButtons"] {
    display: flex;
    align-items: center;
  }
  
  div[class$="homeHeaderButtons"] button {
    margin-left: 1rem;
    height: 3.6rem;
  }
  
  div[class="dropdownMoreActions"] button {
    width: 40% !important;
    height: 4.5rem !important;
  }
  
  div[class$="homeHeaderSearch"] {
    position: relative;
    margin-right: 1rem;
    width: 70rem;
  }
  
  div[class$="homeHeaderSearch"] > div {
    border-radius: 0;
    border-width: 1px;
  }
     
  input {
    z-index: 150;
    height: calc(3.6rem - 2px);
    font-size: 1.4rem;
  }
`;

export const StyledDateRangePicker = withProps<{
  type?: string;
}>()(styled.div)` 
  .rc-calendar-input-wrap {
    display: none;
  }  
  
  .rc-calendar-range-middle {
    display: none;
  }  
  
  .rc-calendar-clear-btn {
    display: none;
  } 
  
  .rc-calendar * {
    font-size: 1.4rem;
    color: black;
    font-family: Verdana;
  }
   
  .rc-calendar-body {
    height: auto;
  }
  
  .rc-calendar a:hover, a:focus {
    outline: none;
    text-decoration: none;
    cursor: pointer;
    color: black;
  }
  
  .rc-calendar-date {
    border-color: #003D81;
    border-radius: 0;
  }
  
  .rc-calendar {
    padding: 1rem 2rem 2rem 2rem;
    border: none;
    border-radius: 0;
    width: auto;
  }
  
  .rc-calendar-range {
    width: auto;
  }
  
  .rc-calendar-today .rc-calendar-date {
    border: none;
  }
  div[aria-selected="true"] {
    color: white;
    background: ${theme().colours.blue};
  } 
  
  .rc-calendar-range .rc-calendar-in-range-cell {
    background: ${theme().colours.blue};
  }
  
  .rc-calendar-range .rc-calendar-in-range-cell > div {
    color: white;
  }
  thead, tbody, tfoot {
    border: none;
  }
  
  .rc-calendar-table th {
    padding-bottom: 1.5rem;
  }  
  
  .rc-calendar-table th span {
    text-transform: uppercase;
    font-weight: bold;
    color: #B3B3B3;
  }
  
  .rc-calendar-table th {
    width: 3.5rem;
  }
    
  .rc-calendar-my-select a {
    font-weight: normal;
    text-transform: capitalize;
  }
  
  .rc-calendar-header {
    border: none;
    margin-bottom: 1rem;
  }
  
  td[role="gridcell"] {
    padding: 0;
    width: 3.5rem;
    height: 3.5rem;
    border: 1px solid #E1E1E1;
  } 
  
  .rc-calendar-body {
    padding-top: 0;
  }
   
  td[role="gridcell"]:hover {
    cursor: pointer;
    background: ${theme().colours.blue};
  } 
  td[role="gridcell"]:hover > div {
    color: white;
    background: ${theme().colours.blue};
  }   
  td[role="gridcell"]:hover > a {
    color: white;
  } 
  td[role="gridcell"] > a:hover {
    color: white;
    background: ${theme().colours.blue};
  }
 
  .rc-calendar-selected-date .rc-calendar-date {
    background: ${theme().colours.blue};
  }
 
  .rc-calendar-range-part {
    width: auto;
    display: inline-flex;
  }
  
  .rc-calendar-date {
    width: auto;
    height: auto;
  }
  
  .rc-calendar-date-panel, .rc-calendar-panel {
    ${({type}) =>
  type === "range" || type === "reporting" ?
    `
      display: flex;
    `
    :
    `
    `
}
  }
  
  .rc-calendar-picker {
    ${({type}) =>
  type === "range" ?
    `
      min-width: 59rem;
      top: 15rem!important;
      left: 1.5rem!important;
    `
    :
    `
      min-width: 300px;
      top: 6.5rem!important;
      left: 0!important;
      
    `
}

    ${({type}) =>
  type === "reporting" ?
    `
      min-width: 58rem;
      top: 5rem!important;
    `
    : ""
}
  }
    
  .rc-calendar-month-panel-selected-cell, .rc-calendar-selected-day {
    background: ${theme().colours.blue}; 
  }
  
  .rc-calendar-month-panel-month , .rc-calendar-month-panel-current-cell > a {
    background: ${theme().colours.blue};
    color: #fff;
    border-radius: 0;
  }  
  .rc-calendar-month-panel-selected-cell > a {
    background: ${theme().colours.blue};
    color: #fff;
  }  
  .rc-calendar-month-panel-selected-cell:hover {
    background: ${theme().colours.blue};
    color: #fff;
  }  
  
  .rc-calendar-year-panel-selected-cell, .rc-calendar-year-panel-selected-cell > a {
    background: ${theme().colours.blue};
    color: #fff;
  }
  
  .rc-calendar-decade-panel-selected-cell, .rc-calendar-decade-panel-selected-cell > a {
      background: ${theme().colours.blue};
      color: #fff;
  }
  
  .rc-calendar-today {
    position: relative;
    background: ${theme().colours.blue};
  }  
  .rc-calendar-today > div {
    color: white;
  }  
  .rc-calendar-today > div::after {
    content: "";
    position: absolute;
    width: 70%;
    left: 15%;
    bottom: 3px;
    border-bottom: 2px solid white;
  }
 

  .rc-calendar-next-month-btn , .rc-calendar-prev-month-btn, .rc-calendar-next-year-btn, .rc-calendar-prev-year-btn {
    font-size: 2.5rem;
    color: #808080;
    top: -2px;
  }  
  
  .rc-calendar-month-select {
    min-width: 5.5rem;
    margin-right: 1rem;
    border: 1px solid grey;
  }  
   
   .rc-calendar-month-select:hover {
    border-color: ${theme().colours.blue};
  } 
   
   .rc-calendar-year-select {
       min-width: 5.5rem;
    border: 1px solid grey;
  }

   .rc-calendar-year-select:hover {
    border-color: ${theme().colours.blue};
  } 

  .rc-calendar-range .rc-calendar-body {
    border: none;
  }
  .rc-calendar-footer {
    display: none;
  }
    
  ${({type}) => type === "range" ? 
    `
    @media screen and (max-width: 1023px) {
      .rc-calendar-picker {
        min-width: 45rem;
        top: 5rem!important;
        left: 0!important;
       }
      .rc-calendar {
        padding: 0.5rem;
      }
      .rc-calendar-header {
        padding: 0.5rem;
      }
    }
    `
    :
    ""}
    
        ${({type}) =>
  type === "reporting" ?
    `
    @media screen and (max-width: 1023px) {
      .rc-calendar-picker {
        min-width: 46rem;
       }
      .rc-calendar {
        padding: 0.5rem;
      }
      .rc-calendar-header {
        padding: 0.5rem;
      }
    }
    `
    : ""
}

  @media screen and (max-width: 767px) {
  
    .rc-calendar-next-month-btn , .rc-calendar-prev-month-btn, .rc-calendar-next-year-btn, .rc-calendar-prev-year-btn {
      padding-top: 0.5rem;
    }  
    
    .rc-calendar-month-select, .rc-calendar-year-select {
      padding: 0.5rem;
    }  
    
    ${({type}) => type === "range" ? 
  `
      .rc-calendar-picker {
      min-width: unset;
      left: 1.5rem!important;
    }
  `
  :
  ""
}
    
    .rc-calendar * {
      font-size: 1.4rem;
    }
    .rc-calendar-header {
      margin-bottom: 2.5rem;
    }
    
    .rc-calendar-table th {
      width: 2rem;
    }
  
    td[role="gridcell"] {
      width: 3.5rem;
      height: 4.5rem;
    } 
        
    .rc-calendar-next-month-btn , .rc-calendar-prev-month-btn {
      font-size: 1.5rem;
    }  
    
    .rc-calendar-date-panel {
      width: 100%;
      flex-direction: column;
    }
    
    .rc-calendar-range-part > div {
      width: 100%;
    }    
    
    .rc-calendar-decade-panel * {
      font-size: 1.2rem;
    }
    
    .rc-calendar-year-panel a {
      width: unset;
    }
    
    .rc-calendar-month-panel a {
      width: unset;
    }
    
    .rc-calendar-range-part:first-child {
      margin-bottom: 1.5rem;
    }
    
    .rc-calendar-picker {
      width: calc(100% - 5.5rem);
    }
      
    .rc-calendar-picker {
      top: 32rem!important;
    }
    
    ${({type}) =>
    type === "range" ?
    `
    `
    :
    `
      .rc-calendar-picker {
        min-width: 100%;
        top: 7rem!important;
      }

      td[role="gridcell"] {
        width: 2rem;
        height: 3.5rem;
      }
    `
    }
    
  }     
`;

export const StyledRangePicker = styled.div` 
  position: relative;
  width: 100%;
`;
export const StyledDatePicker = styled.div` 
  position: relative;
`;

export const StyledModeActiveButtons = styled.div` 
  display: flex;
  align-items: center;
    
  @media screen and (max-width: 1023px) {
    justify-content: space-around;
    z-index: 100;
    position: fixed;
    bottom: 0;
    left: 0;
    height: 7.5rem;
    min-width: 100vw;
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.15);
    background: white; 
    button {
      height: 4.5rem!important;
      width: 40%;
    }
  }
`;

export const StyledGridResetSpace = styled.div` 
  > div {
    padding: 0;
    margin: 0;
  }
  > div > div {
    padding: 0;
    margin: 0;
    max-width: 100%;
  }
`;

export const StyledDropDown = styled.div` 
  button {
    background: ${theme().colours.lightBlue};
    border-radius: 0;   
  } 
  button:hover {
    background: ${theme().colours.hoverBlue};
  } 
  button:active {
    background: ${theme().colours.clickBlue};
  } 
  button span{
    color: ${theme().colours.white};
  }
  
  div[data-role="droplistContent"] {
    padding: 0;
    border-radius: 0;
  }
  
  div[role="menu"] {
    max-width: 100%;
  }  
  
  div[role="menu"] > span {
    color: black;
    height: 4rem;
  }
  
  div[role="menu"] > span:active {
    background: ${theme().colours.lightishGray};
  }
  
  @media screen and (max-width: 1023px) {
    button {
      height: 3.6rem;
      display: flex;
      justify-content: space-around;
      width: 3.6rem;
      background: none;
    }  
    button span {
      color: ${theme().colours.blue}; 
    }
    button:hover {
      background: ${theme().colours.lightishGray};
    }
    button:hover span {
      color: ${theme().colours.lightBlue}; 
    }
    .moreCertificateDropDownActions div[class^="Droplist"] {
      width: 100%;
      display: block;
      margin-left: 1rem;
    }
    .moreCertificateDropDownActions button {
      width: 100%;
      background-color: ${theme().colours.lightBlue};
    }
    .moreCertificateDropDownActions button:hover {
      background-color: ${theme().colours.hoverBlue};
    }
    .moreCertificateDropDownActions button:active,
    .moreCertificateDropDownActions button:focus {
      background-color: ${theme().colours.clickBlue};
    }
    .moreCertificateDropDownActions button span {
      color: ${theme().colours.white};
    }
  }
`;

export const StyledRubleButton = styled.div` 
  width: 3.6rem;
  height: 3.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${theme().colours.blue};
  
  :hover {
    background: #EDF1F6;
    color: ${theme().colours.lightBlue};
  }
`;

export const StyledAgreementsDropDown = styled.div`
  cursor: pointer;
  margin-left: 2rem;
  
  div[class^="Droplist__Trigger"] {
     background: white;   
  }  
    
  div[class^="Droplist__Trigger"] > div > span {
     color: black;   
  }
  
  div[class^="Droplist__Trigger"] > div > span:last-child {
     display: inline-flex;
     align-items: center;   
  }
 
  div[data-role="droplistContent"] {
    padding: 0;
    border-radius: 0;
  }
  
  div[role="menu"] {
    max-width: 100%;
  }  
  
  div[role="menu"] > span {
    color: black;
    height: 4rem;
  }
  
  div[role="menu"] > span:active {
    background: ${theme().colours.lightishGray};
  }
  
  @media screen and (max-width: 1023px) {
    div[class^="Droplist__Trigger"] > div > span:first-child {
     opacity: 0.5;
     display: block;   
    }
  }
`;

export const StyledBillComponent = styled.div` 
  padding: 0 18%;
  h1 {
    margin: 1rem 0 4rem 0;
  }
  min-height: calc(100vh - 6.5rem);
  @media screen and (max-width: 1023px) {
    padding: 0;
  }
`;

export const StyledBillCard = styled.div` 
  color: black;
  background: #FFFFFF;
  box-shadow: 0px 2px 20px rgba(0, 61, 129, 0.1);
  width: calc(100% - 6rem);
  padding: 3rem;
  margin-bottom: 2rem;
  
  h1 {
    color: black;
  }
  span {
    color: black;
    opacity: 0.5;
  }
`;
export const StyledModalBody = styled.div` 
  background: white;
  text-align: center;
  padding: 0 2rem 4rem 2rem;
  
  @media screen and (max-width: 767px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const StyledUploadModalBody = styled.div` 
  display: flex;
  flex-direction: column;
  background: white;
  padding: 0 3rem 1.5rem 3rem;
  
  h1 {
    margin-bottom: 2rem;
  }
  
  button {
    padding: 0;
    margin: 1.5rem 0 0 0;
  }
  a {
    color: inherit;
  } 
  a:hover {
    color: inherit;
  }
  a:focus {
    outline: none;
  }
  
  p {
    color: black;
    opacity: 0.5;
    padding-bottom: 1rem;
  }
  
  label {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin: 1.5rem 0 3rem 0;
    text-decoration: underline;
    color: ${theme().colours.blue};
  }  
  label > a {
    display: flex;
    align-items: center;
  }
  label:hover {
    color: ${theme().colours.hoverBlue};
  }
  label span {
    margin-left: 1rem;
  }
  h3 {
    color: black;
    font-weight: normal!important;
    margin: 0 0 3rem 0;
  }
`;

export const StyledModalHeader = styled.div` 
  display: flex;
  justify-content: flex-end;
  background: white;
  header {
    padding: 1.5rem 1.5rem 0 0;
  }
`;

export const StyledUploadFileArea = styled.div` 
  position: relative;
  height: 15rem;
  width: 100%;
  border: 1px dashed grey;
  
  :hover {
    border-color: ${theme().colours.lightBlue};
  }
  
  > div {
    color: black;
    opacity: 0.5;
    position: absolute;
    top: 1rem;
    left: 1.5rem;
    z-index: 150;
  }
  input {
    outline: none;
    opacity: 0;
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 100;
  }
`;

export const StyledQrCodeArea = styled.div` 
  display: flex;
  justify-content: space-around;
  width: 100%;
  height: auto;
`;

export const StyledStatusAction = withProps<{
  color?: string;
}>()(styled.div)` 
  display: flex;
  align-items: center;
  ${({color}) => `
  color: ${color};
  `}
  span:first-child {
    margin-right: 0.8rem;
    display: flex;
    align-items: center;
  }
`;

export const StyledMobileTable = styled.div` 
  background: white; 
  padding: 2rem 3rem;
  border-bottom: 1px solid #E6E6E6;
  div[class="mobileTableHeader"] {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.5rem;
  }
  div[class="mobileTableHeader"] h2 {
    display: inline-block;
    font-size: 1.4rem;
    font-weight: bold;
  }
  div[class="mobileTableHeader"] p {
    padding-left: 1rem;
    display: inline-block;
  }
  div[class="mobileTableFooter"] span {
    opacity: 0.4;
  }
`;

export const StyledRubleInformation = styled.div` 
  background: white; 
  padding: 0 1.5rem;
  z-index: 1;
  > div {
    padding: 1.5rem 0;
    border-bottom: 1px solid #E6E6E6;
  }  
  > div:last-child {
    border-bottom: none; 
  }  
  > div > span:first-child {
    opacity: 0.7;
  }
  @media screen and (max-width: 1023px) {
    position: fixed;
    bottom: 0;
    left: 0;
    width: calc(100% - 3rem);
    > div {
    display: flex;
    justify-content: space-between;
  }
  }
`;
export const StyledMobileRubleInformation = styled.div` 
  div[class="darkBackground"] {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: black;
    opacity: 0.5;
    top: 0;
    left: 0;
    // z-index: 150;
  }
`;

export const StyledMobileUploadButton = styled.div` 
  position: fixed;
  bottom: 1.5rem;
  width: calc(100% - 8rem);
  @media screen and (max-width: 767px) {
    width: calc(100% - 3rem);
  }
`;

export const StyledFilter = styled.div`
  p {
    margin-bottom: 1rem;
  }
  width: 100%;
  background: white;
  
  div[class^="Field"] {
    margin-top: 0;
  }
  
  label {
    display: inline-flex;
    align-items: center;
    margin: 0 2rem 0.5rem 0;
  }
  
  label > div > span > span > svg {
    width: 3rem;
    height: 3rem;
  }
  
  div[class="filterButtons"] {
    margin-top: 3rem;
    width: 50%;
    display: flex;
    align-items: center;
  }
  
  div[class="filterButtons"] button {
    height: 4rem;
    max-width: 16rem;
  }
  
  div[class="statusCheckBoxFilter"] > div {    
    display: inline-box;
    width: 33.3%;
    min-width: 15rem;
  }
    
  div[class="filter_dataRange_wrapper"] {    
    margin-top: 1rem;
  }
  
  
  
  @media screen and (max-width: 767px) {
    margin-top: 0;
    padding: 0 1.5rem 2rem 1.5rem; 
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    overflow-y: auto;
    overflow-x: hidden;
    
    label {
    display: flex;
    }

    div[class="statusCheckBoxFilter"] {
      display: flex;
      width: 100%;
      flex-direction: column;
    }
    div[class="statusCheckBoxFilter"] > div {
      width: 100%;
    }
    
  }
`;


export const StyledFilterMobileHeader = styled.div`
  padding: 1rem 2rem 0 0; 
  display: flex;
  justify-content: flex-end;
`;

export const StyledFilterDropDown = styled.div` 
  div[class^="styledContentContainer"] > div {
    right: 0;
    transform: translate3d(0, 4rem, 0px)!important;
  }
  div[class^="styledContentContainer"] > div > div {
    padding: 1.5rem 1.5rem 2rem 1.5rem; 
    height: 100%;
    border-radius: 0;
    max-height: unset;
  }
  div[role="menu"] {
    max-width: unset;
  }
`;

export const StyledStatusItemTransportLog = withProps<{
  isActive?: boolean;
}>()(styled.div)`
  width: 3.5rem;
  height: 3.5rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
  :hover {
    background: ${theme().colours.lightishGray};
    color: ${theme().colours.lightBlue};
  }
  :first-child {
    margin-right: 1rem;
  } 
  ${({isActive}) =>
  isActive ?
    `
    color: ${theme().colours.blue};
  `
    :
    `
    color: #CCCCCC;
  `
}
`;

export const StyledStickyWrapper = styled.div` 
  position: sticky;
  z-index: 100;
  background: white;
  top: 6rem;
`;

export const StyledPagination = styled.div` 
  > div {
    justify-content: center;
  }
  background: white;
  button {
    border-radius: 0;
  }
`;

export const StyledFilterModeIcon = styled.span` 
  color: #D23630;
  position: absolute;
  top: -3px;
  right: 5px;
`;

export const StyledSearchIcon = styled.div` 
  padding-left: 1.5rem;
  padding-right: 0.5rem;
  line-height: 100%;
`;

export const StyledInlineDialog = styled.div`
  margin-left: 3rem;
  > button {
    height: 3.6rem;
    width: 100%;
  } 
  > div {
    max-width: unset;
    max-height: unset;
    border-radius: 0;
    padding: 1.5rem;
  }
  
  @media screen and (max-width: 1023px) {
    margin-left: 0;
    
    
  }
`;
export const StyledInlineDialogContent = styled.div`
  p {
    display: flex;
    justify-content: space-between;
    
    margin: 0;
    color: black;
    padding-bottom: 1.5rem;
    margin-top: 1.5rem;
    border-bottom: 1px solid ${theme().colours.lightishGray};
  }
  
  p > span:first-child {
    opacity: 0.5;
  }
  
  h3 {
    color: black;
    font-size: 1.4rem;
    margin-top: 1.5rem;
    font-weight: bold;
  } 
`;

export const StyledEmptyView = styled.div`
 flex-direction: column;
 justify-content: center;
 background: white; 
 h3 {
  margin: 0;
  padding: 1.5rem;
 }
`;


