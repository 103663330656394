import React                     from "react";
import Portal                    from '@atlaskit/portal';
import {ModalTransitionConsumer} from './ModalTransition';
import {layers}                  from '@atlaskit/theme/constants';

export const Modal = (props) => {
  return (
    <ModalTransitionConsumer>
      {() => (
        <Portal zIndex={layers.modal()}>
          {props.children}
        </Portal>
      )}
    </ModalTransitionConsumer>
  )
};
