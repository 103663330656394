import {styled} from '../../styled/styled-components';
import { theme } from "../../styled/theme";

export const StyledFeaturesComponent = styled.div`
  min-height: calc(100vh - 12rem);
  background-color: #F7F9FB;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 0 auto;
  padding: 6rem  10vw;
  h1 {
    font-size: 4rem;
    color: #003D81;
    margin-bottom: 3rem;
  }
  p {
    display: flex;
    align-items: center;
    font-weight: normal;
    font-size: 1.6rem;
  }
  p svg {
    margin-right: 1.5rem;
  }
  
  @media (max-width: 1023px) {
    min-height: 40vh;
  }
`;

export const StylesLoginFormLayout = styled.div`
  padding: 2rem 10vw;
  min-height: calc(100vh - 8rem);
  a {
    outline: none;
    width: 0;
  }
  a div {
    margin: 0 0 6rem 0;
  }
  
  h1 {
    font-size: 4rem;
    margin-bottom: 3rem;
  }
  
  display: flex;
  flex-direction: column;
  
  div[class="content"] {
    display: flex;
    align-items: center;
    flex: 1 0 auto;
  }
  
  div[class="footer"] {
    flex: 0 0 auto;
  }
  
  div[class="footer"] span {
    color: black;
    font-size: 1.6rem;
    font-weight: bold;
  }
  
  div[class="footer"] span:hover {
    cursor: pointer;
    color: ${theme().colours.lightBlue}!important;
  }
  
  div[class="footer"] span a {
    color: inherit;
    text-decoration: none;
  }
  
  div[class="footer"] p {
    opacity: 0.5;
  }
  
 @media (max-width: 1023px) {
    min-height: calc(60vh - 8rem);
  }
  svg {
    margin-right: 1rem;
  }
`;

export const StyledLoginFormComponent = styled.div`
  h1 {
    font-size: 4rem;
  }
  width: 100%;
  
  form div {
    border-radius: 0;
    border-width: 1px;
    margin: 0 0 2rem 0;
  }
  
  input {
    padding-left: 1.5rem;
    height: calc(6rem - 2px);
    font-size: 1.4rem;
  }
`;
export const StyledAuthGrid = styled.div`
  div[spacing="cosy"] {
    padding: 0;
  }
  div > div {
    margin: 0;
    max-width: 100%;
  }
`;

export const StyledPasswordButton = styled.div`
  button{
    padding: 0;
    margin-top: 3rem;
  }
  button span {
    margin: 0;
  }
`;

export const StyledTabs = styled.div`
  div[role="tablist"] > div {
    padding-bottom: 1rem;
    margin-right: 3rem;
  }
  div[role="tabpanel"] {
    padding: 0;
  }
  div[role="tabpanel"] form {
    margin-top: 2rem;
    width: 100%;
  }
`;
